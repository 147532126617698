// FUENTES
@font-face {
    font-family: 'Queens-Typewriter';
    src: url('/fonts/Kingthings_Trypewriter.ttf') format('truetype');
}

@font-face {
    font-family: 'Philosopher';
    src: url('/fonts/Philosopher-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Fog';
    src: url('/fonts/FoglihtenNo04-070.otf') format('opentype');
}

@font-face {
    font-family: 'Abbasy';
    src: url('/fonts/Abbasy.ttf') format('truetype');
}


// TITLE IN CATEGORIES PAGES
.type-title {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-family: Fog;
    font-size: 3rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Philosopher;
}

a {
    font-family: Philosopher;
}


// HEADER BAR
$header-background-color: #800056;
$header-background-color-dark: #ff5ac8;

// BACKGROUND COLORS
$global-background-color: #bebebe;
$table-background-color: #a8a8a8;
$code-background-color: #272c3400;
$global-background-color-dark: #2e2e2e;
$table-background-color-dark: #4a4a4a;
$code-background-color-dark: #272c3400;

// TEXTS ON DARK MODE
$global-font-color-dark: #e3e3e3;
$global-font-secondary-color-dark: #a8a8a8;
$global-link-color-dark: #ff5ac8;
$global-link-hover-color-dark: #fff;
$single-link-color-dark: #ff5ac8;
$single-link-hover-color-dark: #fff;
$header-hover-color-dark: #fff;


// TEXTS ON LIGTH MODE
$global-font-color: #3b3b3b;
$global-font-secondary-color: #505050;
$global-link-color: #800056;
$global-link-hover-color: #fff !important;
$single-link-color: #800056;
$single-link-hover-color: #fff !important;
$header-hover-color: #161209;


// HR
.single .content hr {
    border-top: 2px solid white !important;
}

// MENU ITEMS
.menu-item {
    color: rgb(255, 255, 255) !important;
}
.fas.fa-search.fa-fw {
    color: rgb(255, 255, 255) !important;
}
$search-background-color: #b9b9b9;
$search-background-color-dark: #363636;
.fas.fa-arrow-up.fa-fw {
    color: white;
}
#header-mobile .header-container .header-wrapper .menu-toggle span {
    background: white !important;
}
.menu-item.delimiter {
    border-left: 1px solid white !important;
}

// FULL WIDTH
.container.landing-page {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}

// BACKGROUND IMAGE FOR LANDING PAGE
/* Image background for landing page */
.landing-page {
    //background-image: url('/img/bg/Stars_BG.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100% !important;
    opacity: 0; /* Start with image hidden */
    animation: fadeIn 2s ease-in-out forwards; /* Apply fade-in animation */
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


// SUBTITLE on HOME
.typeit {
    font-size: xx-large;
    line-height: 1.2;
    margin-top: 3em;
    color: white;
}

// CONTENT TITLES
.single-title.animate__animated.animate__pulse.animate__faster {
    margin-bottom: 4rem;
    margin-top: 4rem;
    text-align: center;
}

// CONTENT SUBTITLE
.single-subtitle {
    font-style: italic;
    font-size: 1rem !important;
}

// POEMAS
.poem {
    //font-style: italic;
    font-family: Queens-Typewriter;
    font-size: 1.1rem;
    line-height: 2;
    margin-bottom: 6rem !important;
    white-space: pre-wrap;
}

// TEXTO RAYADO
.rayado {
    display: inline;
    text-decoration: line-through;
}

// CARDS STYLES

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    max-width: 80vw;
}
.card {
    background-color: #a12dd05a;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    min-width: 100%;
}
.card h3 {
    margin-top: 0;
}
.more {
    text-align: center;
    padding-right: 2rem;
}
.view-all {
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 4rem;
}


// SPACING FOR FOOTER
.page.single.special {
    margin-bottom: 4rem;
}
.next {
    margin-bottom: 4rem;
}

// SPACING ON CONTENT TITLES
.post-meta {
    margin-bottom: 2rem;
}

// AVATAR IMAGE ON ABOUT PAGE
.about-avatar {
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    opacity: .6;
}

// HERO IMAGE
.hero {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
}

// ÍCONOS
.moonlink {
    background-image: url('/img/id/moonlink.png');
    background-size: contain;
    width: 1rem;
    height: 1rem;
    display: inline-block;
}

.donation-moon {
    background-image: url('/img/id/donation-moon.png');
    background-size: contain;
    width: 2rem;
    height: 2rem;
    display: inline-block;
}

.rol {
    background-image: url('/img/id/rol.png');
    background-size: contain;
    height: 1rem;
    width: 1.0625rem;
    display: inline-block;
}

.chorito {
    background-image: url('/img/id/chorito.png');
    background-size: contain;
    width: 1.3125rem;
    height: 1rem;
    display: inline-block;
}

.letras {
    background-image: url('/img/id/letras.png');
    background-size: contain;
    width: 1.25rem;
    height: 1rem;
    display: inline-block;
}


// DONATIONS BUTTON
.donation-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.donation-button {
    background-color: #a12dd05a;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'Abbasy';
    font-size: 2rem;
    align-items: center;
}